import React, { useState, useEffect } from 'react'
import { SSRProvider } from 'react-bootstrap'
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Col, Row, Container, Card } from "react-bootstrap"
import OthebMarkdown from '../components/othebmarkdown'
import Layout from "../components/layout"
import Seo from "../components/seo"
import seedrandom from "seedrandom"
import { Decoration, animations, decovariants } from '../components/decoration'
import { getNewsLink } from "../components/navigationhelper"

function ContentPage(props) {
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setIsLoading(false)
    }, []);

    const { allStrapiNavigation, strapiNeuigkeiten, allStrapiNeuigkeiten, allStrapiWebsiteTextbaustein } = props.data
    const filteredNews = allStrapiNeuigkeiten.nodes.filter((n) => { return n.kategorie.id === strapiNeuigkeiten.kategorie?.id })
    var slug = "/meldungen/"
    if (strapiNeuigkeiten.kategorie.id === 2) {
        slug = "/blog/"
    }
    const meldungenPath = (props.pageContext.locale === 'en' ? '/en' : '') + slug
    const activePage = (props.pageContext.locale === 'en' ? '/en' : '') + slug + strapiNeuigkeiten.Slug
    const currentIndex = filteredNews.findIndex(n => { return n.Slug === strapiNeuigkeiten.Slug })
    var nextNews = null
    var prevNews = null
    var nextLink = null
    var prevLink = null
    if (currentIndex < filteredNews.length) {
        nextNews = filteredNews[currentIndex + 1]
        nextLink = getNewsLink(allStrapiNavigation.nodes, nextNews)
    }
    if (currentIndex > 0) {
        prevNews = filteredNews[currentIndex - 1]
        prevLink = getNewsLink(allStrapiNavigation.nodes, prevNews)
    }
    var titel = 'Titel'
    var einleitung = 'Einleitung'
    var text = 'Text'
    var cta = 'Button'
    if (props.pageContext.locale === 'en') {
        titel = 'TitelEn'
        einleitung = 'EinleitungEn'
        text = 'TextEn'
        cta = 'ButtonEn'
    }
    const order1 = 'order-1 order-lg-1'
    const order2 = 'order-2 order-lg-2'
    const rng = seedrandom(strapiNeuigkeiten[titel])

    return (
        <SSRProvider>
            <Layout news={{ titel: strapiNeuigkeiten[titel], path: activePage }} isLoading={isLoading} locale={props.pageContext.locale} textbausteine={allStrapiWebsiteTextbaustein.nodes} pages={allStrapiNavigation.nodes} activePage={activePage}>
                <svg className="gradient">
                    <radialGradient id="orangeGradient" r="150%" cx="30%" cy="107%">
                        <stop stopColor="#d65a22" offset="0" />
                        <stop stopColor="#ffab40" offset="1" />
                    </radialGradient>
                </svg>
                <Seo title={strapiNeuigkeiten[titel]} keywords={[]} description={strapiNeuigkeiten[einleitung]} locale={props.pageContext.locale} />
                <Container className="newsdetail">
                    <h1>
                        <Decoration top={-50} right={-100} animation={Math.floor(rng() * animations.length)} variant={decovariants[Math.floor(rng() * decovariants.length)]}/>
                        {strapiNeuigkeiten[titel]}
                    </h1>
                    <Row>
                        <Col className={order1} xs="12" lg="6">
                            {strapiNeuigkeiten.Bilder ? (
                                strapiNeuigkeiten.Bilder.map((b, j) => {
                                    return (
                                        <GatsbyImage className="mb-3" key={"newsbild" + j} image={b.localFile.childImageSharp.gatsbyImageData} alt="" />
                                    )
                                })
                            ) : (
                                <StaticImage src="../../static/images/Strandsteg_Hintergrund.jpg" width={650} alt="" className="card-img-top mb-3" />
                            )}
                        </Col>
                        <Col className={order2 + " text"} xs="12" lg="6">
                            <p><strong>{strapiNeuigkeiten[einleitung]}</strong></p>
                            <ReactMarkdown components={OthebMarkdown}>
                                {strapiNeuigkeiten[text]}
                            </ReactMarkdown>
                        </Col>
                    </Row>
                    <Decoration bottom={-100} left={-50} animation={Math.floor(rng() * animations.length)} variant={decovariants[Math.floor(rng() * decovariants.length)]}/>
                    <Row className="mt-5">
                        <Col xs="3">
                            {prevNews && (
                                <Card>
                                    {prevNews.Bilder[0] ? (
                                        <GatsbyImage image={prevNews.Bilder[0].localFile.childImageSharp.gatsbyImageData} alt="" className="card-img-top" />
                                    ) : (
                                        <StaticImage src="../../static/images/Strandsteg_Hintergrund.jpg" width={500} alt="" className="card-img-top" />
                                    )}
                                    <Card.Body>
                                        <h3>{prevNews[titel]}</h3>
                                        {prevLink ? (
                                            <AnchorLink to={(props.pageContext.locale === 'en' ? '/en' : '') + prevLink.path}>{prevNews.kategorie[cta]}</AnchorLink>
                                        ) : (
                                            <Link to={meldungenPath + prevNews.Slug}>&lt; {prevNews.kategorie[cta]}</Link>
                                        )}
                                    </Card.Body>
                                </Card>
                            )}
                        </Col>
                        <Col xs="6"></Col>
                        <Col xs="3">
                            {nextNews && (
                                <Card>
                                    {nextNews.Bilder[0] ? (
                                        <GatsbyImage image={nextNews.Bilder[0].localFile.childImageSharp.gatsbyImageData} alt="" className="card-img-top" />
                                    ) : (
                                        <StaticImage src="../../static/images/Strandsteg_Hintergrund.jpg" width={500} alt="" className="card-img-top" />
                                    )}
                                    <Card.Body>
                                        <h3>{nextNews[titel]}</h3>
                                        {nextLink ? (
                                            <AnchorLink to={(props.pageContext.locale === 'en' ? '/en' : '') + nextLink.path}>{nextNews.kategorie[cta]}</AnchorLink>
                                        ) : (
                                            <Link to={meldungenPath + nextNews.Slug}>{nextNews.kategorie[cta]} &gt;</Link>
                                        )}
                                    </Card.Body>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </SSRProvider>
    )
}

export default ContentPage
export const query = graphql`
  query($id: Int) {
      strapiNeuigkeiten(strapiId: {eq: $id}) {
        Datum
        Einleitung
        EinleitungEn
        Slug
        Sticky
        Text
        TextEn
        Titel
        TitelEn
        kategorie {
            id
            Button
            ButtonEn
        }
        Bilder {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED
                        width: 650
                    )
                }
            }
        }
      }
      allStrapiWebsiteTextbaustein {
        nodes {
          Text
          TextEn
          Titel
        }
      }
      allStrapiNeuigkeiten(
        sort: {
         fields: [Sticky, Datum], 
         order: [DESC, DESC]
       }) {
       nodes {
         Einleitung
         EinleitungEn
         Slug
         Titel
         TitelEn
         link_intern{
            navigation {
              id
              path
            }
          }
         kategorie {
           id
           Button
           ButtonEn
         }
         Bilder {
           localFile {
             childImageSharp {
               gatsbyImageData(
                 layout: CONSTRAINED
                 width: 500
               )
             }
           }
         }
       }
     }
      allStrapiNavigation {
        nodes {
          items {
            id
            slug
            path
            related {
              Titel
              TitelEn
              hideInMenu
              showInFooter
            }
          }
          path
          strapiId
          related {
            Titel
            TitelEn
            hideInMenu
            showInFooter
          }
          slug
        }
    }
  }
`